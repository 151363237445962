import React from 'react'

const App = () => {
  return (
    <div>
      <h1>Al Mamzar Car Wash</h1>
    </div>
  )
}

export default App
